import React, { useState, useEffect } from "react";
import { Box, Button, Heading, Input, Spinner, useToast, useTheme } from "@chakra-ui/react";
import { FaFileUpload } from "react-icons/fa";
import axios from "axios";

const Import = ({ warehouse, supplier, retailer, organisation, distributor }) => {
  const theme = useTheme();
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const toast = useToast();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (event) => {
    event.preventDefault(); // Prevent any default button click behavior
    setIsUploading(true);

    //const file = event.target.files[0]; // Get the selected file
    const formData = new FormData();
    formData.append("file", file); // This name must match the 'file' in upload.single('file')
    formData.append("organisationcode", organisation);
    formData.append("suppliercode", supplier);
    formData.append("retailercode", retailer);
    formData.append("distributorcode", distributor);
    formData.append("userid", localStorage.getItem("userid"));
    formData.append("warehousecode",warehouse);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/orders/import-orders`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        responseType: "blob",
      });
      console.log(response.headers["content-type"]);

      if (response.headers["content-type"] == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "skippedOrders.xlsx"); // Default filename, can be dynamic
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast({
          title: "Orders Import Successful!",
          description: "skippedOrders.xlsx has been downloaded.",
          status: "success",
          duration: 3500,
          isClosable: true,
        });
      } else if (response.headers["content-type"] == "application/json; charset=utf-8") {
        toast({
          title: "All Orders Imported Successfully!",
          description: "",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      } else {
        console.log(response);
        toast({
          title: "Technical Error occured!",
          description: "Please inform IT",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }

      setIsUploading(false);
      setFile(null);
      document.getElementById("file-input").value = "";
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsUploading(false);
      toast({
        title: "Upload failed.",
        description: "There was an error uploading the file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box mt={4} p={4} boxShadow="md">
      <Heading size="md" mb={4}>
        Upload Excel File
      </Heading>
      <Input type="file" onChange={handleFileChange} display="none" id="file-input" />
      <label htmlFor="file-input">
        <Button as="span" mt={4} leftIcon={isUploading ? <Spinner /> : <FaFileUpload />} onClick={file ? handleFileUpload : undefined} isLoading={isUploading} loadingText="Uploading" colorScheme="customRed" variant="outline">
          {file ? "Submit" : "Upload"}
        </Button>
      </label>
      {file && (
        <Box mt={4} p={2} borderWidth="1px" borderRadius="md">
          <Heading size="sm">File Information:</Heading>
          <Box mt={2}>
            <strong>File Name:</strong> {file.name}
          </Box>
          <Box>
            <strong>Supplier:</strong> {supplier}
          </Box>
          <Box>
            <strong>Retailer:</strong> {retailer}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Import;

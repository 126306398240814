import React, { useState, useEffect } from "react";
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, IconButton, Button, Input, Spinner, useTheme, useToast } from "@chakra-ui/react";
import { FaUpload, FaEdit,  FaEye, FaPrint } from "react-icons/fa";
import ViewDetails from "./viewDetails/ViewDetails";
import Pop from "./Pop";
import axios from "axios";

import FilteringDebriefed from "./filtering/FilteringDebriefed";

const Debriefed = ({ organisation, warehouse, supplier, retailer }) => {
  const theme = useTheme();
  const toast = useToast();

  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [currentPopIndex, setCurrentPopIndex] = useState(null);
  const [isPopOpen, setIsPopOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);
  const [viewActive, setViewActive] = useState(null);

  const [invoiceFilter, setInvoiceFilter] = useState("");
  const [poFilter, setPoFilter] = useState("");
  const [storeFilter, setStoreFilter] = useState("");
  const [page, setPage] = useState(0); // Add state for current page
  const [limit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const [distributors, setDistributors] = useState([]);
  const [loadingState, setLoadingState] = useState({});


  const [filters, setFilters] = useState([]);
  const [invoice_numbers, setInvoice_numbers] = useState([]);
  const [po_numbers, setPo_numbers] = useState([]);
  const [store_codes, setStore_codes] = useState([]);
  const [store_descriptions, setStore_descriptions] = useState([]);
  const [partial_deliverys, setPartial_deliverys] = useState([]);
  const [evalueteds, setEvalueteds] = useState([]);
  const [created_dates, setCreated_dates] = useState([]);
  const [user_names, setUser_names] = useState([]);

  //filters
  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage * limit < totalCount) {
      // Check if the new page is within the range
      setPage(newPage);
      fetchInvoices();
    }
  };
  const handleFilterRefresh = async () => {
    await fetchInvoices();
  };

  const fetchInvoices = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transactions`,
        { organisation, retailer, supplier, filters, limit, offset: page * limit, entry: "debriefs.debriefed" ,
          invoiced:true,debriefed:true});
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      console.log(response.data.data);
      setTableData(response.data.data.transactions);
      setTotalCount(response.data.data.totalCount);


      setInvoice_numbers(response.data.data.invoice_numbers);
      setPo_numbers(response.data.data.po_numbers);
      setStore_codes(response.data.data.store_codes);
      setStore_descriptions(response.data.data.store_descriptions);
      setPartial_deliverys(response.data.data.partial_deliverys);
      setEvalueteds(response.data.data.evalueteds);
      setCreated_dates(response.data.data.created_dates);
      setUser_names(response.data.data.user_names);

    } catch (error) {
      toast({
        title: "Technical Error",
        description: "Unexpcted error occured",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  const fetchDistributors = async () => {
    //setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-dd-distributors`, { organisation:organisation});
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      setDistributors(response.data.data.distributors);
    } catch (error) {
      toast({
        title: "Technical Error",
        description: "Unexpcted error occured",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    } finally {
     // setLoading(false);
    }
  };
  const fetchDebriefStatus = async () => {
    //setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/debriefs/get-debrief-status`, { });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      setStatusOptions(response.data.data.debrief_status);
    } catch (error) {
      toast({
        title: "Technical Error",
        description: "Unexpcted error occured",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    } finally {
     // setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvoices();
    fetchDistributors();
    fetchDebriefStatus();
  }, [supplier, warehouse, retailer,filters]);

  //details
  const handle_viewDetails = async(index) => {
    let  debrief=tableData[index];
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transaction-items`, { invoice_number: debrief.invoice_number });
    debrief.transaction_items = response.data.data.transaction_items;
    setSelectedRow(debrief);
    setViewActive(true);
  };
  const handle_closeViewDetails = (index) => {
    setSelectedRow(null);
    setViewActive(null);
  };

  const updatePop = (updatedRow) => {
    const updatedTableData = tableData.map((item) => {
      if (item.po_number === updatedRow.po_number) {
        return updatedRow;
      }
      return item;
    });
    setTableData(updatedTableData);
  };
  const handle_pop = async (index, mode) => {
    if (mode == "open") {
      setCurrentPopIndex(index);
      setIsPopOpen(true);
    } else {
      setIsPopOpen(false);
      setCurrentPopIndex(null);
    }
  };

  const getStatusText = (value) => {
    const status = statusOptions.find((option) => option.value == value);
    const text = status ? status.text : null;
    return text;
  };

  const downloadDistributors = async (distributorcode) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [distributorcode]: true,
    }));

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/debriefs/download-debriefs-by-distributor`,
        { organisation, distributor: distributorcode, retailer, debriefed_yn: true },
        {
          responseType: "blob", // important to get the response as a blob
        }
      );

      // Create a URL for the blob and download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `debriefs_${distributorcode}.pdf`); // set the file name
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error uploading:", error);
    } finally {
      // Reset loading state after the operation is complete
      setLoadingState((prevState) => ({
        ...prevState,
        [distributorcode]: false,
      }));
    }
  };

  const handleFilterSubmit = (selectedFilters) => {
    setFilters(selectedFilters);
  };

  if (loading) return <Spinner />;

  if (viewActive) {
    return <ViewDetails transaction_items={selectedRow.transaction_items} debrief={selectedRow} onClose={handle_closeViewDetails} />;
  }

  return (
    <Flex direction="column" height="78vh" justifyContent="space-between">
      <Box>
        {/* <Flex gap="20px">
          <Input placeholder="Filter by Invoice #" value={invoiceFilter} onChange={(e) => setInvoiceFilter(e.target.value)} mb={4} />
          <Input placeholder="Filter by PO #" value={poFilter} onChange={(e) => setPoFilter(e.target.value)} mb={4} />
          <Input placeholder="Filter by store" value={storeFilter} onChange={(e) => setStoreFilter(e.target.value)} mb={4} />
          <Button variant="outline" px="8" colorScheme="customRed" onClick={handleFilterRefresh} mb={4}>
            Refresh
          </Button>
        </Flex> */}
        <Flex flexDirection={"column"}>
        <FilteringDebriefed onFilterSubmit={handleFilterSubmit} invoice_numbers={invoice_numbers} po_numbers={po_numbers} store_codes={store_codes} store_descriptions={store_descriptions} partial_deliverys={partial_deliverys} evalueteds={evalueteds} created_dates={created_dates} user_names={user_names}/>
          <Box maxHeight="550px" overflowY={"scroll"}>
            <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
              <Thead>
                <Tr textAlign="center">
                  <Th textAlign="center" style={{ width: "50px" }}>
                    View
                  </Th>
                  <Th>Supplier</Th>
                  <Th>Invoice #</Th>
                  <Th>Po #</Th>
                  <Th>Retailer</Th>
                  <Th>Store Code</Th>
                  <Th>Store Name</Th>
                  <Th>Region</Th>
                  <Th>Distributor</Th>
                  <Th># Cases Invoiced</Th>
                  <Th># Cases Returned</Th>
                  <Th>Status</Th>
                  <Th>Comment</Th>
                  <Th textAlign="center" style={{ width: "50px" }}>
                    POD
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData.map((row, index) => (
                  <Tr key={index}>
                    <Td textAlign="center">
                      <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="Upload" onClick={() => handle_viewDetails(index, "open")} />
                    </Td>
                    <Td>{row.supplier_name}</Td>
                    <Td>{row.invoice_number}</Td>
                    <Td>{row.po_number}</Td>
                    <Td>{row.retailercode}</Td>
                    <Td>{row.store_code}</Td>
                    <Td>{row.store_description}</Td>
                    <Td>{row.store_region}</Td>
                    <Td>{row.distributor_name}</Td>
                    <Td>{row.quantity}</Td>
                    <Td>{row.cases_returned}</Td>
                    <Td>{getStatusText(row.debrief_status)}</Td>
                    <Td>{row.debrief_comment}</Td>
                    <Td textAlign="center">
                      <IconButton border="2px" borderColor="white.300" size="sm" icon={row.pop.isset ? <FaEdit /> : <FaUpload />} aria-label="Upload" onClick={() => handle_pop(index, "open")} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {currentPopIndex !== null && <Pop row={tableData[currentPopIndex]} isOpen={isPopOpen} onClose={() => handle_pop(-1, "close")} onUpdate={updatePop} />}
          </Box>
          <Flex justifyContent="space-between" mt={4}>
            <Button
              onClick={() => handlePageChange(page - 1)}
              isDisabled={page === 0} // Disable Previous button if on the first page
            >
              Previous
            </Button>
            <Button
              onClick={() => handlePageChange(page + 1)}
              isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
            >
              Next
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Flex gap="20px">
        {distributors.map((distributor) => (
          <Button key={distributor.value} leftIcon={loadingState[distributor.value] ? <Spinner /> : <FaPrint />} onClick={() => downloadDistributors(distributor.value)} isLoading={loadingState[distributor.value]} loadingText="Downloading" cursor="pointer" colorScheme="customRed" variant="outline">
            {distributor.text}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
};

export default Debriefed;

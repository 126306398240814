import React, { useState, useEffect } from "react";
import { Box, Button, Input, Select, Table, Thead, Tbody, Tr, Th, Td, Divider, useColorModeValue, useTheme, useToast } from "@chakra-ui/react";
import { FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";

const CaptureStep1 = ({ transaction_items, debriefData, onSubmit }) => {
  const theme = useTheme();
  const toast = useToast();
  const [articles, setArticles] = useState([...transaction_items]);
  const [errors, setErrors] = useState([]);
  const grayColor = useColorModeValue("#e6e6e6", "#dedede");

  useEffect(() => {
    onSubmit(articles);
  }, [articles, onSubmit]);

  const handle_goodStock = (index, value) => {
    let field = "good_stock";
    //let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    //setArticles(updatedArticles);
    upd_article_TOTALS(index,field,value);
    
  };
  const handle_badStock = (index, value) => {
    let field = "bad_stock";
    //let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    //setArticles(updatedArticles);
    upd_article_TOTALS(index,field,value);
    
  };
  const handle_missing = (index, value) => {
    let field = "missing_stock";
    //let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    //setArticles(updatedArticles);
    upd_article_TOTALS(index,field,value);
  };
  const handle_delivered = (index, value) => {
    let field = "cases_delivered";
    //let updatedArticles = articles.map((article, i) => (i === index ? { ...article, [field]: value } : article));
    //setArticles(updatedArticles);
    upd_article_TOTALS(index,field,value);
    
  };
  const upd_article_TOTALS = (index, field, value) => {
    const updatedArticles = articles.map((article, i) => {
      if (i === index) {
        const updatedArticle = {
          ...article,
          [field]: parseInt(value) || 0,
        };
  
        const good_stock = parseInt(updatedArticle.good_stock) || 0;
        const bad_stock = parseInt(updatedArticle.bad_stock) || 0;
        const missing_stock = parseInt(updatedArticle.missing_stock) || 0;
  
        const cases_returned = good_stock + bad_stock + missing_stock;
  
        return {
          ...updatedArticle,
          cases_returned, // Only updating cases_returned
        };
      }
      return article;
    });
  
    setArticles(updatedArticles);
  };
  


  CaptureStep1.verify = async () => {
    try {

      const res = await axios.post(`${process.env.REACT_APP_API_URL}/debriefs/upd-in-progress-debrief-items`, {
        transaction_items: articles,
        invoice_number: debriefData.invoice_number,
      });

      return true;
    } catch (error) {
      toast({
        title: "Technical Error",
        description: "Unexpcted error occured",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  return (
    <Box p={4} borderWidth={1} borderRadius="md" bg="white">
      <Box mb={4}>
        {/* <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th>PO Number</Th>
              <Th>Order Date</Th>
              <Th>Delivery Date</Th>
              <Th>Store Code</Th>
              <Th>Store Name</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key={1}>
              <Td>{step1Data.poNumber}</Td>
              <Td>{step1Data.orderDate}</Td>
              <Td>{step1Data.deliveryDate}</Td>
              <Td>{step1Data.storeCode}</Td>
              <Td>{step1Data.storeName}</Td>
            </Tr>
          </Tbody>
        </Table> */}
      </Box>
      <Divider />
      <Box mb={4}>
        <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
          <Thead>
            <Tr>
              <Th bg={grayColor}></Th>
              <Th bg={grayColor}></Th>
              <Th bg={grayColor}></Th>
              {debriefData.partial_delivery && <Th style={{ textAlign: "center" }}>Delivered</Th>}
              <Th colSpan={3} bg={grayColor} style={{ textAlign: "center" }}>
                Not Delivered
              </Th>
            </Tr>
            <Tr>
              <Th>Article Number</Th>
              <Th>Article Name</Th>
              <Th>Invoiced cases</Th>
              {debriefData.partial_delivery && <Th></Th>}
              <Th>Good Stock</Th>
              <Th>Bad Stock</Th>
              <Th>Missing Stock</Th>
            </Tr>
          </Thead>
          <Tbody>
            {articles.map((article, index) => (
              <Tr key={index}>
                <Td> {article.articlecode} </Td>
                <Td> {article.article_name} </Td>
                <Td> {article.quantity} </Td>
                {debriefData.partial_delivery && (
                  <Td>
                    <Input value={article.cases_delivered} onChange={(e) => handle_delivered(index, e.target.value)} />
                  </Td>
                )}
                <Td>
                  <Input value={article.good_stock} onChange={(e) => handle_goodStock(index, e.target.value)} />
                </Td>
                <Td>
                  <Input value={article.bad_stock} onChange={(e) => handle_badStock(index, e.target.value)} />
                </Td>
                <Td>
                  <Input value={article.missing_stock} onChange={(e) => handle_missing(index, e.target.value)} />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default CaptureStep1;

import React, { useState, useEffect, useCallback } from "react";
import { Table, Thead, Tbody, Tr, Td, Th, IconButton, Spinner, Button, Box, Flex, useTheme, useToast } from "@chakra-ui/react";
import { FaEye, FaFileInvoice, FaCar, FaTimes, FaCheck, FaEdit } from "react-icons/fa";
import FilteringInProgress from "../filtering/FilteringInProgress";
import ViewInvoice from "../ViewInvoice";
import Capture from "./capture/Capture"; //Edit InProgress Invoices
import CommentPopup from "./CommentPopup";

import axios from "axios";

const InProgressTable = ({ organisation, warehouse, supplier, retailer }) => {
  const theme = useTheme();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [popupInvoice_number, setPopupInvoice_number] = useState(null);

  const [page, setPage] = useState(0); // Add state for current page
  const [limit] = useState(50);
  const [totalCount, setTotalCount] = useState(0);

  const [filters, setFilters] = useState([]);
  const [invoice_numbers, setInvoice_numbers] = useState([]);
  const [po_numbers, setPo_numbers] = useState([]);
  const [store_codes, setStore_codes] = useState([]);
  const [store_descriptions, setStore_descriptions] = useState([]);
  const [order_dates, setOrder_dates] = useState([]);
  const [delivery_dates, setDelivery_dates] = useState([]);
  const [created_dates, setCreated_dates] = useState([]);
  const [user_names, setUser_names] = useState([]);

  const handleViewInvoice = async (invoice, index) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transaction-items`, { invoice_number: invoice.invoice_number });

      invoice.transaction_items = response.data.data.transaction_items;

      setSelectedInvoice(invoice);
      setSelectedIndex(index);
      setIsPopupOpen(true);
    } catch (error) {
      toast({
        title: "Failed to load order items",
        description: "Technical error occurred",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleCloseInvoice = () => {
    setIsPopupOpen(false);
    setSelectedInvoice(null);
    setSelectedIndex(null);
  };
  const handleEdit = async (invoice, index) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transaction-items`, { invoice_number: invoice.invoice_number });

    invoice.transaction_items = response.data.data.transaction_items;

    setIsPopupOpen(false);
    setSelectedInvoice(invoice);
    setSelectedIndex(index);
    setEditMode(true);
  };

  const handleEditComplete = async (transaction, index) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress`, {
        transaction,
        userid: localStorage.getItem("userid"),
      });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        title: "technical Error",
        description: "exception occured updating the invoioce",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }

    const newInvoices = invoices.map((invoice, i) => {
      if (i === index) {
        return transaction;
      }
      return invoice;
    });
    setEditMode(false);
    setSelectedInvoice(null);
    setSelectedIndex(null);
    await fetchInvoices();
  };
  const handleCancelEdit = () => {
    setEditMode(false);
    setSelectedInvoice(null);
    setSelectedIndex(null);
  };
  const fetchInvoices = async () => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/generic/get-transactions`,
       { organisation, retailer, supplier, filters, limit, offset: page * limit, entry: "invoices.in-progress" });
    if (response.data.status != "success") {
      toast({
        title: response.data.title,
        description: response.data.message,
        status: response.data.status,
        duration: 2500,
        isClosable: true,
      });
      return;
    }

    setInvoices(response.data.data.transactions);
    setTotalCount(response.data.data.totalCount);
    setInvoice_numbers(response.data.data.invoice_numbers);
    setPo_numbers(response.data.data.po_numbers);
    setStore_codes(response.data.data.store_codes);
    setStore_descriptions(response.data.data.store_descriptions);
    setOrder_dates(response.data.data.order_dates);
    setDelivery_dates(response.data.data.delivery_dates);
    setCreated_dates(response.data.data.created_dates);
    setUser_names(response.data.data.user_names);
    setLoading(false);
  };

  useEffect(() => {
    console.log(filters);
    fetchInvoices();
  }, [organisation, supplier, retailer, page, filters]);

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage * limit < totalCount) {
      // Check if the new page is within the range
      setPage(newPage);
      fetchInvoices();
    }
  };

  const click_invoiced = async (invoice_number) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress-invoiced`, {
        userid: localStorage.getItem("userid"),
        invoiced: true,
        invoice_number,
        organisation,
        supplier,
        retailer,
      });
      console.log(response.data);
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      await fetchInvoices();
    } catch (error) {
      toast({
        title: "technical Error",
        description: "exception occured updating the invoioce",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const click_parked = async (invoice_number, parked) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress-parked`, {
        userid: localStorage.getItem("userid"),
        parked,
        invoice_number,
      });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      await fetchInvoices();
    } catch (error) {
      toast({
        title: "technical Error",
        description: "exception occured updating the invoioce",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const click_cancelled = async (invoice_number) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/invoices/upd-in-progress-cancelled`, {
        userid: localStorage.getItem("userid"),
        cancelled: true,
        invoice_number,
      });
      if (response.data.status != "success") {
        toast({
          title: response.data.title,
          description: response.data.message,
          status: response.data.status,
          duration: 2500,
          isClosable: true,
        });
      }
      await fetchInvoices();
    } catch (error) {
      toast({
        title: "technical Error",
        description: "exception occured updating the invoioce",
        status: "error",
        duration: 2500,
        isClosable: true,
      });
    }
  };
  const handleFilterSubmit = (selectedFilters) => {
    setFilters(selectedFilters);
  };

  if (loading) return <Spinner />;

  return (
    <Flex flexDirection={"column"}>
      {editMode ? null : <FilteringInProgress onFilterSubmit={handleFilterSubmit} invoice_numbers={invoice_numbers} po_numbers={po_numbers} store_codes={store_codes} store_descriptions={store_descriptions} order_dates={order_dates} delivery_dates={delivery_dates} created_dates={created_dates} user_names={user_names} />}

      <Box>
        {editMode ? (
          <Capture invoice={selectedInvoice} index={selectedIndex} organisation={organisation} warehouse={warehouse} supplier={supplier} retailer={retailer} onSubmit={handleEditComplete} onCancel={handleCancelEdit} />
        ) : (
          <Box maxHeight="640px" overflowY={"scroll"}>
            <Table variant="striped" size="sm" colorScheme="gray" borderColor="gray.200" borderWidth="2px" borderStyle="solid">
              <Thead>
                <Tr>
                  <Th>View</Th>
                  <Th>Edit</Th>
                  <Th>Supplier</Th>
                  <Th>Po #</Th>
                  <Th>Store Code</Th>
                  <Th>Store Name</Th>
                  <Th>Region</Th>
                  <Th>Distributor</Th>
                  <Th># Cases</Th>
                  <Th>Delivery Date</Th>
                  <Th>Invoice Amount (incl)</Th>
                  <Th>Invoice</Th>
                  <Th>Park</Th>
                  <Th>Cancel</Th>
                  <Th>Comment</Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoices.map((invoice, index) => (
                  <Tr key={invoice.id}>
                    <Td>
                      <IconButton variant="outline" colorScheme="customRed" size="sm" icon={<FaEye />} aria-label="View" onClick={() => handleViewInvoice(invoice, index)} />
                    </Td>
                    <Td>
                      <IconButton variant="outline" colorScheme="green" icon={<FaEdit />} size="sm" aria-label="Park Invoice" onClick={() => handleEdit(invoice, index)} />
                    </Td>
                    <Td>{invoice.supplier_name}</Td>
                    <Td>{invoice.po_number}</Td>
                    <Td>{invoice.store_code}</Td>
                    <Td>{invoice.store_description}</Td>
                    <Td>{invoice.store_region}</Td>
                    <Td>{invoice.distributor_name}</Td>
                    <Td>{invoice.quantity}</Td>
                    <Td>{invoice.delivery_date}</Td>
                    <Td>{invoice.cost_incl}</Td>
                    <Td>
                      <IconButton onClick={() => click_invoiced(invoice.invoice_number)} icon={invoice.invoiced ? <FaCheck color="green" /> : <FaFileInvoice />} size="sm" border="2px" borderColor="green.300" aria-label="Open Invoice" />
                    </Td>
                    <Td>
                      <IconButton onClick={() => click_parked(invoice.invoice_number, !invoice.parked)} icon={invoice.parked ? <FaCheck color="gray.500" /> : <FaCar />} size="sm" border="2px" borderColor={"gray.300"} aria-label="Park Invoice" />
                    </Td>
                    <Td>
                      <IconButton onClick={() => click_cancelled(invoice.invoice_number)} icon={<FaTimes color="red" />} size="sm" border="2px" borderColor="red.300" aria-label="Park Invoice" />
                    </Td>
                    <Td>

                      <CommentPopup invoice_number={invoice.invoice_number} initialComment={invoice.invoice_comment || ""} fetchInvoices={fetchInvoices} isOpen={popupInvoice_number === invoice.invoice_number} onOpen={() => setPopupInvoice_number(invoice.invoice_number)} onClose={() => setPopupInvoice_number(null)} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )}
        {selectedInvoice && <ViewInvoice isOpen={isPopupOpen} onClose={handleCloseInvoice} invoice={selectedInvoice} />}
      </Box>
      <Flex justifyContent="space-between" mt={4}>
        <Button
          onClick={() => handlePageChange(page - 1)}
          isDisabled={page === 0} // Disable Previous button if on the first page
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePageChange(page + 1)}
          isDisabled={(page + 1) * limit >= totalCount} // Disable Next button if there are no more invoices
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
};

export default InProgressTable;
